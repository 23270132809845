/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-console */

import html2canvas from 'html2canvas';

import { BiteUrl, ErrorCode, ErrorMessage } from '@biteinc/common';
import { ApiHeader, BiteLogType, ClientApiVersion, FlashBridgeMessage } from '@biteinc/enums';

import { useStore } from '~/stores';

import { ApiService } from './gcn_maitred_request_manager';

declare global {
  interface Window {
    bridgeIsReady: boolean;
  }
}

export const getKioskPreviewBridge = ({
  gcnBuildName,
  authToken,
  showCover,
}: {
  gcnBuildName: string;
  authToken: string;
  showCover: () => void;
}): Bridge => {
  const apiVersion = ClientApiVersion.SupportsErrorMessage;

  function isObject(value: any): boolean {
    return !Array.isArray(value) && value !== null && typeof value === 'object';
  }

  function valueWithoutNulls(value: any): any {
    if (Array.isArray(value)) {
      return value.map((element: any) => {
        return valueWithoutNulls(element);
      });
    }
    if (isObject(value)) {
      const cleaned: Record<string, any> = {};
      Object.keys(value).forEach((k) => {
        const v = value[k];
        if (Array.isArray(v)) {
          cleaned[k] = valueWithoutNulls(v);
        } else if (isObject(v)) {
          cleaned[k] = valueWithoutNulls(v);
        } else if (v !== null && v !== undefined) {
          cleaned[k] = v;
        }
      });
      return cleaned;
    }
    return value;
  }

  function clone(data: any): any {
    if (data === undefined) {
      return data;
    }

    try {
      return JSON.parse(JSON.stringify(data));
    } catch (e) {
      console.log('ERROR CONVERTING TO TRUE JSON', e);
      console.log('Original Data:', data);
      throw e;
    }
  }

  function getHostFromApiService(apiService: ApiService): string {
    switch (apiService) {
      case ApiService.LogsApiV1:
      case ApiService.LoyaltyApiV1:
      case ApiService.LoyaltyApiV2:
      case ApiService.Maitred:
      case ApiService.OrdersApiV1:
      case ApiService.OrdersApiV2:
      case ApiService.PaymentsApiV2:
      case ApiService.RecommendationsApiMaitred:
        return '';
      case ApiService.RecommendationsApi:
        return BiteUrl.biteGatewayUrl(window.env);
    }
  }

  // promised async method
  const browserRequest = async (
    apiService: ApiService,
    type: 'GET' | 'POST',
    path: string,
    data: any,
    timeout: number,
  ): Promise<any> => {
    // In Orders API V1 (specifically on iOS), we send the data to the native layer through the
    // bridge, which serializes it. It means that if data contains any non json objects
    // (i.e. Backbone models) they will get converted to json as the bridge calls JSON.stringify and
    // Backbone models return their attributes when that happens.
    // We shouldn't be sending Backbone models in request body but nevertheless this behavior hid
    // that bug we should mimic it correctly.
    const isUsingV1 = false;
    const requestData = isUsingV1 ? clone(data) : data;
    const location = useStore.getState().bridge.location;
    const abortController = new AbortController();
    const url = `${getHostFromApiService(apiService)}${path}`;

    console.log(`browserRequest -> ${type} ${url}`, requestData);

    setTimeout(() => {
      abortController.abort();
    }, timeout);

    const res = await fetch(url, {
      signal: abortController.signal,
      method: type,
      body: type.toLowerCase() === 'get' ? undefined : JSON.stringify(requestData),
      headers: {
        [ApiHeader.ApiVersion]: `${apiVersion}`,
        ...(authToken && {
          Authorization: `Bearer ${authToken}`,
        }),
        [ApiHeader.LocationId]: location._id,
        [ApiHeader.OrgId]: location.orgId,
        ...(type.toLowerCase() === 'get' ? undefined : { 'content-type': 'application/json' }),
        [ApiHeader.GcnBuildName]: gcnBuildName,
      },
    }).catch((_err) => {
      // We can assume that an error here is going to be due to the network
      throw {
        success: false,
        error: {
          code: ErrorCode.NetworkRequestTimedOut,
          message: ErrorMessage.EXTERNAL_NETWORK,
        },
      };
    });

    if (res.ok) {
      const json = await res.json();
      console.log(`request <- ${res.status} ${type} ${path}`, JSON.stringify(json));

      if (isUsingV1 && (isObject(json) || Array.isArray(json))) {
        try {
          const cleanResponse = valueWithoutNulls(json);
          console.log('Clean response for v1', cleanResponse);

          return cleanResponse;
        } catch (e) {
          console.log('ERROR REMOVING NULLS', e);
          console.log('Original Data:', json);
          throw e;
        }
      }

      return json;
    }

    const errorJson = await res.json();
    console.log(`request <- ${res.status} ${type} ${path}`, JSON.stringify(errorJson));
    throw {
      success: false,
      error: errorJson,
    };
  };

  // instead of using mongoId or bson to generate ids, just randomly generate
  const pseudoObjectId = (): string => {
    const h = 16;
    const s = (ss: number): string => {
      return Math.floor(ss).toString(h);
    };
    return (
      s(Date.now() / 1000) +
      ' '.repeat(h).replace(/./g, () => {
        return s(Math.random() * h);
      })
    );
  };

  const sendLog = async (data: any, type: BiteLogType = BiteLogType.Device): Promise<void> => {
    const location = useStore.getState().bridge.location;
    const locationId = location._id;
    const orgId = location.orgId;

    const logJson = {
      ...data,
      type,
      clientId: pseudoObjectId(),
      clientName: 'kiosk-preview',
      createdAt: Date.now(),
    };
    await browserRequest(
      ApiService.LogsApiV1,
      'POST',
      `/api/orgs/${orgId}/locations/${locationId}/logs`,
      logJson,
      2000,
    );
  };

  // create a transaction
  const demoPayTransactionWithAmount = (amount: number): any => {
    const location = useStore.getState().bridge.location;
    const locationId = location._id;
    const orgId = location.orgId;
    const maskPan = '123456******9876';
    const now = Date.now();
    const clientId = pseudoObjectId();
    const authCode = `${Math.floor(Math.random() * 899999) + 100000}`;
    const paymentsApiVersion = 'V2';

    const transactionJson: Record<string, any> = {
      amount,
      authCode,
      clientId,
      createdAt: now,
      finishedAt: now,
      // TODO: get from constants
      gateway: 0, // Gateway.None,
      gatewaySoftwareVersion: 'DEMO_PAY',
      last4: maskPan.slice(-4),
      locationId,
      maskPan,
      orgId,
      cardSchemeId: 2, // CardSchemeId.MasterCard,
      result: 1, // TransactionResult.Approved,
      startedAt: now,
      state: 2, // TransactionState.Committed,
      transactionTime: now,
      type: 0, // TransactionType.Sale,
      updatedAt: now,
      paymentsApiVersion, // PaymentsApiVersion
      userAction: 0,
      amountAuthorized: amount,
      currencyCode: 'USD', // Currency.USD
    };

    return transactionJson;
  };

  // generate a sequential client number
  let clientNumberInteger = 0;
  const generateClientNumber = (): string => {
    if (clientNumberInteger >= 99) {
      clientNumberInteger = 1;
    } else {
      clientNumberInteger += 1;
    }
    if (clientNumberInteger < 10) {
      return `Z0${clientNumberInteger}`;
    }
    return `Z${clientNumberInteger}`;
  };

  // browser bridge
  // eslint-disable-next-line no-undef
  let sendFromBridgeToGcnFunction: any;
  return {
    init: (sendFromBridgeToGcn: any) => {
      sendFromBridgeToGcnFunction = sendFromBridgeToGcn;
      // stub
      console.log('browser bridge init');
      window.bridgeIsReady = true;
    },
    send: async (payload: any, callback: any) => {
      switch (payload.event) {
        case FlashBridgeMessage.GO_HOME: {
          showCover();
          break;
        }
        case 'ready':
          // dunno what to do with these yet
          break;
        case FlashBridgeMessage.IMAGE:
          console.log('kiosk-preview-bridge -> loading image', payload);
          return payload.imageUrl;
        case FlashBridgeMessage.SEND_ORDER:
          {
            const location = useStore.getState().bridge.location;

            // V1
            const { skipPaymentStep, remainingBalance, orderPayload2 } = payload.checkoutSession;

            if (skipPaymentStep || remainingBalance === 0) {
              // do nothing
            } else {
              // Add a fake transaction
              const demoPayTransaction = demoPayTransactionWithAmount(remainingBalance);
              orderPayload2.order.transactions = [demoPayTransaction];
            }

            sendFromBridgeToGcnFunction({ updatePaymentFlowState: 'sending_order' });

            orderPayload2.order.clientNumber = generateClientNumber();
            if (!orderPayload2.order.guestId) {
              orderPayload2.order.guestId = pseudoObjectId();
            }
            const locationPrefix = `/api/orgs/${location.orgId}/locations/${location._id}`;
            const orderPath = `${locationPrefix}/orders`;
            browserRequest(ApiService.OrdersApiV1, 'POST', orderPath, orderPayload2, 10000).then(
              (response) => {
                // send either sending_order_failed or sending_order_success.
                // pass along checkoutSession, print happens from response
                sendFromBridgeToGcnFunction({
                  updatePaymentFlowState: 'sending_order_success',
                  order: { ...response.data.order },
                });
                sendFromBridgeToGcnFunction({ updatePaymentFlowState: 'local_printing' });
                sendFromBridgeToGcnFunction({ updatePaymentFlowState: 'local_printing_done' });
                sendFromBridgeToGcnFunction({
                  updatePaymentFlowState: 'order_complete',
                  order: { ...response.data.order },
                });
                browserRequest(
                  ApiService.Maitred,
                  'POST',
                  `${locationPrefix}/guests`,
                  {
                    _id: orderPayload2.order.guestId,
                  },
                  2000,
                );
              },
            );
          }
          break;
        case FlashBridgeMessage.MAITRED_REQUEST:
          {
            if (!payload.request) {
              console.log('BAD maitredRequest', JSON.stringify(payload));
              break;
            }
            const { apiService, path, body, timeout, method, requestId } = payload.request;

            console.log('capture maitredRequest event', {
              apiService,
              method,
              path,
              timeout,
              requestId,
            });
            console.log('request payload', JSON.stringify(body));
            browserRequest(apiService, method, path, body, timeout)
              .then((response) => {
                callback(response);
              })
              .catch((err) => {
                callback(err);
              });
          }
          break;
        case 'makePayment':
          callback(demoPayTransactionWithAmount(payload.amountInCents));
          break;
        case 'printReceipt':
          window.top?.postMessage(
            { type: 'kiosk-preview::print', payload: payload.printPayload.printJobs },
            '*',
          );
          callback({});
          break;
        case 'userDidRequestToPrintReceipt':
          callback({});
          break;
        case 'orderCompleted': {
          const location = useStore.getState().bridge.location;

          browserRequest(
            ApiService.Maitred,
            'POST',
            `/api/orgs/${location.orgId}/locations/${location._id}/guests`,
            {
              _id: payload.guestId,
            },
            2000,
          );
          break;
        }
        case FlashBridgeMessage.DEVICE_GAZEBO_LOG: {
          console.log(FlashBridgeMessage.DEVICE_GAZEBO_LOG, payload);
          if (payload.takeScreenshot) {
            try {
              const canvas = await html2canvas(document.body);
              payload.screenshotData = canvas
                .toDataURL('image/jpeg', 0.8)
                // native clients don't include data content types in their base64 data,
                // so strip it out for consistency
                .replace('data:image/jpeg;base64,', '');
            } catch {
              console.error('Failed to get a screenshot');
            }
          }
          await sendLog(payload);
          break;
        }
        case FlashBridgeMessage.ENABLE_REDUCED_VIEWPORT_HEIGHT: {
          window.top?.postMessage({ type: 'kiosk-preview::reduced-height-enable' }, '*');
          break;
        }
        case FlashBridgeMessage.DISABLE_REDUCED_VIEWPORT_HEIGHT: {
          window.top?.postMessage({ type: 'kiosk-preview::reduced-height-disable' }, '*');
          break;
        }
        default:
          console.log('unknown bridge message event', JSON.stringify(payload));
      }
    },

    async sendAsync(data: any) {
      return new Promise((resolve) => {
        this.send(data, (response: any) => {
          resolve(response);
        });
      });
    },
    sendLog: () => {},
  };
};
