import $ from 'jquery';
import _ from 'underscore';

import { Strings } from '@biteinc/common';
import { MenuItemSaleUnitHelper } from '@biteinc/enums';

import { localizeStr, str } from '~/app/js/localization/localization';

import { setImage } from '../gcn_app_view';
import GcnHtml from '../gcn_html';
import ScreenReaderHelper from '../screen_reader_helper';
import { GCNView } from './gcn_view';

export const GCNOrderedItemView = GCNView.extend({
  className: 'ordered-item-view',
  template: _.template(
    // prettier-ignore
    '<div class="item-card" aria-hidden="true">' +
      '<div class="item-image"></div>' +
      '<div class="item-details">' +
        '<div class="item-quantity-container">' +
          '<div class="item-quantity"></div>' +
        '</div>' +
        '<div class="item-details-container">' +
          '<div class="item-name font-title" role="heading" aria-level="1"></div>' +
          '<div class="item-options font-body"></div>' +
        '</div>' +
      '</div>' +
      '<div class="close-cell">' +
        '<div class="close-button" role="button">✕</div>' +
      '</div>' +
    '</div>',
  ),

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this.options = options;
  },

  scrollToBottom() {
    this.$('.item-details').animate(
      {
        scrollTop: this.$('.item-details')[0].scrollHeight,
      },
      2400,
    );
  },

  _renderCalories() {
    if (this.options.showCalories && this.model.item.shouldShowNutritionInfo()) {
      const calorieRange = this.model.calorieCounter();
      this.$el.find('.calories').toggleClass('hidden', !calorieRange.min);
      this.$el.find('.calories-total').html(GcnHtml.stringFromCalorieRange(calorieRange));
    }
  },

  render() {
    const self = this;
    const enableMultipleQuantityItems = !!gcn.menu.settings.get('enableMultipleQuantityItems');

    this.$el.html(this.template);

    const menuItem = this.model.item;
    if (menuItem.hasArr('images')) {
      const images = menuItem.get('images');
      setImage(images[images.length - 1], this.$('.item-image'));
    } else {
      // Apply a default image.
      self.$('.item-image').addClass(gcn.menu.placeholderImageClass(menuItem));
    }
    // Calories is added to the name div so that we retain the font size set in any custom css
    const caloriesHtml = this.options.showCalories && this.model.item.getCaloriesHtml();
    const itemName = menuItem.displayNameWithoutCalories();
    const quantity = this.model.orderedPO?.get('quantity');
    if (quantity) {
      // quantity aren't set when viewing an item in a customize-flow-step-view
      this.$('.item-quantity').html(`${quantity}×`);
    }
    this.$('.item-name').html(caloriesHtml ? `${itemName}<br />${caloriesHtml}` : itemName);
    this.$('.item-name').html(
      caloriesHtml
        ? `${GcnHtml.htmlFromString(itemName)}<br />${caloriesHtml}`
        : GcnHtml.htmlFromString(itemName),
    );
    const caloriesAriaLabel = this.options.showCalories && menuItem.getCaloriesAriaLabel();
    this.$('.item-name').attr(
      'aria-label',
      caloriesAriaLabel
        ? `${ScreenReaderHelper.prepareAriaLabel(itemName)}, ${caloriesHtml}`
        : ScreenReaderHelper.prepareAriaLabel(itemName),
    );
    this._renderCalories();

    const $options = this.$('.item-options');
    const poDisplayName = this.model.orderedPO?.po?.get('displayName');
    if (poDisplayName) {
      const ariaLabel = ScreenReaderHelper.prepareAriaLabel(poDisplayName);
      $options.append(
        $(
          `<div class="price-option" aria-label="${ariaLabel}">${GcnHtml.htmlFromString(
            poDisplayName,
          )}</div>`,
        ),
      );
    }
    const modRefs = this.model.getSelectedAndDeselectedModRefsForPriceOption();

    const displayDefaultModsInCart = gcn.menu.settings.get('displayDefaultModsInCart');
    const displayZeroDollarModsInCart = gcn.menu.settings.get('displayZeroDollarModsInCart');
    const displayDeselectedModsInCart = gcn.menu.settings.get('displayDeselectedModsInCart');
    const displayModCodesInCart = gcn.menu.settings.get('displayModCodesInCart');

    if (modRefs.length) {
      let modNamesHtml = [];
      let modNamesAriaLabel = [];
      modRefs.forEach(({ isSelected, modRef }) => {
        if (
          !displayDefaultModsInCart &&
          modRef.selectedByDefaultQuantity &&
          modRef.selectedByDefaultQuantity <= quantity
        ) {
          return;
        }
        if (!displayZeroDollarModsInCart && modRef.price === 0) {
          return;
        }
        if (!displayDeselectedModsInCart && !isSelected) {
          return;
        }

        let subModPrefix = '';
        if (displayModCodesInCart && modRef.autoSelectedSubMods?.length) {
          subModPrefix = modRef.autoSelectedSubMods
            .map((subMod) => {
              return subMod.name;
            })
            .join(', ');
        }
        // Add the space
        if (subModPrefix) {
          subModPrefix += ' ';
        }

        const modDisplayName = isSelected
          ? modRef.displayName
          : localizeStr(Strings.DESELECTED_MOD_PREFIX, [modRef.displayName]);
        const displayName =
          enableMultipleQuantityItems && modRef.quantity
            ? `${modRef.quantity}× ${modDisplayName}`
            : modDisplayName;
        modNamesHtml.push(GcnHtml.htmlFromString(`${subModPrefix}${displayName}`));
        modNamesAriaLabel.push(ScreenReaderHelper.prepareAriaLabel(displayName));
      });
      modNamesHtml = modNamesHtml.join(', ');
      modNamesAriaLabel = modNamesAriaLabel.join(', ');
      $options.append(
        $(`<div class="addons" aria-label="${modNamesAriaLabel}">${modNamesHtml}</div>`),
      );
    }

    const orderedPO = this.model.orderedPO;
    if (this.options.showPrice && orderedPO) {
      let priceDesc = '';
      const weight = orderedPO.get('weight');
      const saleUnit = orderedPO.get('saleUnit');

      if (weight && saleUnit) {
        const saleUnitStr = MenuItemSaleUnitHelper.notation(saleUnit) || 'ea';
        const weightStr = (weight * quantity).toFixed(2);
        const plural = weightStr !== '1.00' ? 's' : ' ';
        const unitPriceStr = GcnHtml.stringFromPrice(orderedPO.get('unitPrice'));

        priceDesc += ` (${weightStr} ${saleUnitStr}${plural} @ $${unitPriceStr}/${saleUnitStr})`;
      } else if (quantity !== 1) {
        const singleItemPriceStr = GcnHtml.stringFromPrice(this.model.getSubtotal());
        priceDesc += ` (${quantity} @ $${singleItemPriceStr}/ea)`;
      }

      this.$('.item-details-container').append(
        GcnHtml.htmlFromPrice(this.model.getTotal(), { className: 'font-body', suffix: priceDesc }),
      );
    }

    if (this.model.get('specialRequest')) {
      $options.append(
        $(
          `<div class="special-request">${localizeStr(Strings.SPECIAL_REQUESTS)}: ${this.model.get(
            'specialRequest',
          )}</div>`,
        ),
      );
    }

    const $closeButton = this.$('.close-button');
    if (this.options.closeCallback) {
      $closeButton.onButtonTapOrHold('oivClose', this.options.closeCallback);
    } else {
      this.$('.close-cell').remove();
    }

    $closeButton.attr('aria-label', str(Strings.SR_REMOVE_ITEM_FROM_CART));

    const optionalOptionsDisplay = $options.text()
      ? `, with following options: ${$options.text()}`
      : '';
    const price = this.model.getTotal();
    const optionalPriceDisplay = price ? `, price is: $${GcnHtml.stringFromPrice(price)}` : '';
    const optionalItemQuantity = quantity && quantity > 1 ? `, quantity is: ${quantity}` : '';
    this.$el.attr(
      'aria-label',
      `${ScreenReaderHelper.prepareAriaLabel(
        itemName,
      )}${optionalItemQuantity}${optionalOptionsDisplay}${optionalPriceDisplay}`,
    );

    return this;
  },
});
