import type { I9nSchemaLoyalty } from '@biteinc/common';
import { I9nSchemaBySystem } from '@biteinc/common';
import { LocalizationHelper } from '@biteinc/core-react';
import type {
  CardSchemeId,
  CheckinType,
  Currency,
  CustomerIdentifier,
  CustomerIdentifierInputMethod,
  DefaultModifierDeselectPrintOption,
  EpsonBarcodeFormat,
  FulfillmentMethod,
  LocationPresetTipType,
  LocationState,
  LocationTipsLevel,
  OrderChannel,
  PunchhPrinterBarcodeType,
  Timezone,
} from '@biteinc/enums';
import {
  BitePlatform,
  ClientCapability,
  CouponProvider,
  FulfillmentMethodHelper,
  IntegrationSystem,
  LocationPaymentType,
  LoyaltyAuthMethod,
  LoyaltyInMenuMode,
  RecommendationsFirstLoadVisibility,
} from '@biteinc/enums';

import { LocationUtils } from '~/helpers';

import { localizeStr } from '../localization/localization';
import getQueryParam from '../utils/get_query_param';
import type { GcnModelData } from './gcn_model_ts';
import GcnModelTs from './gcn_model_ts';

class GcnLocation extends GcnModelTs<GcnLocation.Data> {
  usesApiForTerminalPayments(): boolean {
    return this.get('usesApiForTerminalPayments');
  }

  brandName(): string {
    return gcn.menu.settings.get('brandName') || this.get('orgName');
  }

  isClosed(): boolean {
    return !this.hasActiveFulfillmentMethods();
  }

  isClosedFor(fulfillmentMethod: FulfillmentMethod): boolean {
    return !this.get('activeFulfillmentMethods').includes(fulfillmentMethod);
  }

  hasPosI9n(): boolean {
    return this.getPosPartialI9ns().length > 0;
  }

  useOrdersApiV2(): boolean {
    if (window.gcnCapability < ClientCapability.OrdersApiV2) {
      return false;
    }
    if (window.isFlash || window.isGarcon) {
      return !!this.get('useOrdersApiV2Flash');
    }
    return !!this.get('useOrdersApiV2Kiosk');
  }

  hasCouponProvider(): boolean {
    return this.get('couponProvider') !== CouponProvider.None;
  }

  couponsHaveBarcode(): boolean {
    return this.get('couponsHaveBarcode');
  }

  private getPartialI9ns(): GcnLocation.PartialI9n[] {
    return Object.values(this.get('integrationById'));
  }

  getPosPartialI9ns(): GcnLocation.PartialPosI9n<IntegrationSystem.Pos>[] {
    const posPartialI9ns = this.getPartialI9ns().filter(({ system }) => {
      return I9nSchemaBySystem[system].type === 'pos';
    }) as GcnLocation.PartialPosI9n<IntegrationSystem.Pos>[];
    return posPartialI9ns;
  }

  getPunchhSettings(): GcnLocation.PartialPunchhLoyaltyI9n<IntegrationSystem.PunchhLoyalty>['syncedData'] {
    const punchhPartialI9n = this.getPartialI9ns().find(({ system }) => {
      return [IntegrationSystem.Punchh, IntegrationSystem.PunchhOlo].includes(system);
    }) as GcnLocation.PartialPunchhLoyaltyI9n<IntegrationSystem.PunchhLoyalty> | undefined;
    return punchhPartialI9n?.syncedData;
  }

  getLoyaltyIntegration(): GcnLocation.PartialLoyaltyI9n | undefined {
    const partialLoyaltyI9n = this.getPartialI9ns().find(({ system }) => {
      return I9nSchemaBySystem[system].type === 'loyalty';
    }) as GcnLocation.PartialLoyaltyI9n | undefined;
    return partialLoyaltyI9n;
  }

  getLoyaltyI9nSchema(): I9nSchemaLoyalty<IntegrationSystem.Loyalty> | undefined {
    const loyaltyI9n = this.getLoyaltyIntegration();
    return loyaltyI9n && I9nSchemaBySystem[loyaltyI9n.system];
  }

  loyaltySupportsShowingQrCode(): boolean {
    const loyaltyI9n = this.getLoyaltyIntegration();
    return (
      loyaltyI9n?.system === IntegrationSystem.Como &&
      loyaltyI9n.authMethods.some((authMethod) => authMethod === LoyaltyAuthMethod.PhoneNumber)
    );
  }

  getPaymentSystem(): IntegrationSystem.EcommPayment | IntegrationSystem.KioskPayment | undefined {
    return this.get('paymentI9n');
  }

  hasStoredValueIntegration(): boolean {
    return !!this.getStoredValueIntegration();
  }

  getStoredValueSystem(): IntegrationSystem | undefined {
    return this.get('giftCardI9n');
  }

  getStoredValueIntegration():
    | GcnLocation.PartialStoredValueI9n<IntegrationSystem.StoredValue>
    | undefined {
    const storedValueSystem = this.getStoredValueSystem();
    const partialStoredValueI9n = this.getPartialI9ns().find((partialI9n) => {
      return partialI9n.system === storedValueSystem;
    }) as GcnLocation.PartialStoredValueI9n<IntegrationSystem.StoredValue> | undefined;
    return partialStoredValueI9n;
  }

  hasCompCardIntegration(): boolean {
    return !!this.getCompCardIntegration();
  }

  getCompCardSystem(): IntegrationSystem | undefined {
    return this.get('compCardI9n');
  }

  getCompCardIntegration(): GcnLocation.PartialCompCardI9n<IntegrationSystem.CompCard> | undefined {
    const compCardSystem = this.getCompCardSystem();
    const partialCompCardI9n = this.getPartialI9ns().find((partialI9n) => {
      return partialI9n.system === compCardSystem;
    }) as GcnLocation.PartialCompCardI9n<IntegrationSystem.CompCard> | undefined;
    return partialCompCardI9n;
  }

  getSmgSettings(): { header?: string; footer?: string } | undefined {
    const smgPartialI9n = this.getPartialI9ns().find(({ system }) => {
      return system === IntegrationSystem.Smg;
    }) as GcnLocation.PartialSmgI9n | undefined;
    return smgPartialI9n;
  }

  getFulfillmentIntegration(): GcnLocation.PartialFulfillmentI9n | undefined {
    const partialLoyaltyI9n = this.getPartialI9ns().find(({ system }) => {
      return I9nSchemaBySystem[system].type === 'fulfillment';
    }) as GcnLocation.PartialFulfillmentI9n | undefined;
    return partialLoyaltyI9n;
  }

  getMaxSpecialRequestLength(): number {
    return this.getPosPartialI9ns().reduce((maxLength, { syncedData }) => {
      if (syncedData?.specialRequestMaxLength && syncedData.specialRequestMaxLength < maxLength) {
        return syncedData.specialRequestMaxLength;
      }
      return maxLength;
    }, 100);
  }

  /**
   * @description Exclude the dining option if location is closed and this dining option isn't set
   * up to accept future orders.
   * NOTE: Only doing this on flash so that kiosk-preview and kiosk would always show everything.
   * Flash shows a closed screen if location is closed so it should never get here if no dining
   * options are enabled.
   */
  getActiveDiningOptions(): GcnLocation.DiningOption[] {
    const allDiningOptions = this.getDiningOptions();
    if (!window.isFlash) {
      return allDiningOptions;
    }
    return allDiningOptions.filter((diningOption) => {
      return (
        !this.isClosedFor(diningOption.fulfillmentMethod) ||
        (diningOption.futureOrdersEnabled && (diningOption.calendarDaysAheadFutureOrders || 0) > 0)
      );
    });
  }

  getDiningOptions(): GcnLocation.DiningOption[] {
    return this.get('diningOptions');
  }

  getDiningOption(fulfillmentMethod: FulfillmentMethod): GcnLocation.DiningOption | undefined {
    return this.getDiningOptions().find((diningOption) => {
      return diningOption.fulfillmentMethod === fulfillmentMethod;
    });
  }

  getDiningOptionName(fulfillmentMethod: FulfillmentMethod): string {
    const diningOption = this.getDiningOption(fulfillmentMethod);
    return (
      diningOption?.name ||
      localizeStr(LocalizationHelper.localizeEnum.FulfillmentMethod(fulfillmentMethod))
    );
  }

  // Return the customer identifiers configured for this location for the given fulfillmentMethod.
  getCustomerIdentifierOptions(
    fulfillmentMethod: FulfillmentMethod,
  ): GcnLocation.CustomerIdentifierOption[] | undefined {
    return this.getDiningOption(fulfillmentMethod)?.customerIdentifierOptions;
  }

  futureOrdersEnabledOnSomeDiningOption(): boolean {
    return this.getDiningOptions().some((diningOption) => {
      return !!diningOption.futureOrdersEnabled;
    });
  }

  canChangeDiningOptions(): boolean {
    // Can change dining options if:
    const diningOptions = this.getActiveDiningOptions();

    // There are multiple to choose from
    if (diningOptions.length > 1) {
      return true;
    }

    // There's only one, but...
    // It's delivery so we can change the address
    if (FulfillmentMethodHelper.isDelivery(diningOptions[0].fulfillmentMethod)) {
      return true;
    }

    // OR...
    // It's an outpost with more than 1 outpost location
    if (
      FulfillmentMethodHelper.isAnOutpost(diningOptions[0].fulfillmentMethod) &&
      (diningOptions[0].outposts || []).length > 1
    ) {
      return true;
    }

    // OR...
    // It allows future orders so we can change pickup time
    if (diningOptions[0].futureOrdersEnabled) {
      return true;
    }

    return false;
  }

  usesCreditCardToPay(): boolean {
    return this.get('paymentType') === LocationPaymentType.CreditCard;
  }

  /**
   * Flash always accepts payment since cash is not an option for contactless
   */
  takesPayment(): boolean {
    if (window.isFlash) {
      return true;
    }
    return this.get('paymentType') !== LocationPaymentType.CashierOnly;
  }

  showBankedPointsDemo(): boolean {
    return !!this.get('bankedPointsDemo');
  }

  isPrintingOptional(): boolean {
    return !!this.get('optionalPrinting');
  }

  showReprintButton(): boolean {
    return !!this.get('showReprintButton');
  }

  isDeliKioskDemo(): boolean {
    return !!this.get('deliKioskDemo');
  }

  showRewardsDemo(): boolean {
    return !!this.get('rewardsDemo');
  }

  shouldShowClosedScreen(): boolean {
    if (getQueryParam('forcedMenuStructureId')) {
      // If we are force showing a menu structure, then we should not cover it with a closed screen
      return false;
    }
    if (!window.isFlash) {
      // This closed screen does not apply to non-flash channels
      return false;
    }
    if (gcn.menu.settings.get('showClosedScreen')) {
      // Explicitly closed by operator
      return true;
    }

    if (!this.futureOrdersEnabledOnSomeDiningOption()) {
      // Future order availability means they can access the menu at any time
      // If there are no future dining options, then we may have to show a closed screen
      if (!this.hasActiveFulfillmentMethods()) {
        // None of the dining options should be currently accessible to the customer based on their
        // client type
        return true;
      }
    }

    // If none of the dining options are active right now, we need to check if they are active for
    // any days ahead or we are only offering current day ordering and it's now closed.
    if (!this.hasActiveFulfillmentMethods()) {
      const allDiningOptionsAreForCurrentDay = this.getDiningOptions().every((diningOption) => {
        return (
          !diningOption.futureOrdersEnabled ||
          (diningOption.calendarDaysAheadFutureOrders || 0) === 0
        );
      });
      if (allDiningOptionsAreForCurrentDay) {
        return true;
      }
    }

    return false;
  }

  shouldShowLoyaltyInTheMenu(): boolean {
    return (
      gcn.menu.settings.get('loyaltyInMenuMode') !== LoyaltyInMenuMode.Off &&
      !!this.getLoyaltyIntegration() &&
      !window.isFlash &&
      !gcn.screenReaderIsActive
    );
  }

  hasBottomBarButtons(): boolean {
    return LocationUtils.hasBottomBarButtons(
      gcn.menu.settings.attributes,
      gcn.screenReaderIsActive,
    );
  }

  // We don't want to enable this feature on iOS since the screen is already small enough
  allowsReducedHeight(): boolean {
    return LocationUtils.allowsReducedHeight(gcn.menu.get('settings'), gcn.screenReaderIsActive);
  }

  /**
   * @returns True iff at least one of the given dining options is valid. A valid dining option is
   *     among the list of active fulfillment methods
   */
  hasActiveFulfillmentMethods(): boolean {
    return this.get('activeFulfillmentMethods').length > 0;
  }

  shouldMakeRecommendationFirstLoadCall(): boolean {
    return (
      gcn.menu.settings.get('recommendationsFirstLoadVisibility') !==
        RecommendationsFirstLoadVisibility.Hidden ||
      gcn.menu.settings.get('recommendationsInMenuVisibility') !==
        RecommendationsFirstLoadVisibility.Hidden
    );
  }

  // USE_GATEWAY_IN_DEV is a flag to test the gateway in dev
  shouldSendRecommendationsRequestToGateway(): boolean {
    return !!this.get('sendRecommendationsRequestToGateway');
  }

  getPresetTips(): GcnLocation.PresetTip[] {
    return this.get('presetTips');
  }

  usesTallScreenUI(): boolean {
    return !!this.get('usesTallScreenUI');
  }

  shouldSkipIncludeUtensils(fulfillmentMethod: FulfillmentMethod): boolean {
    const diningOption = this.getDiningOption(fulfillmentMethod);
    return (
      !!diningOption?.skipIncludeUtensilsOnFlashOrders ||
      !!diningOption?.skipIncludeUtensilsOnKioskOrders
    );
  }

  useSideNavMenu(): boolean {
    if (!gcn.menu.settings.get('useSideNavMenu')) {
      return false;
    }

    switch (window.platform) {
      case BitePlatform.KioskAndroid:
      case BitePlatform.KioskAndroidGarcon:
      case BitePlatform.KioskChromeOsGarcon:
      case BitePlatform.KioskIos:
      case BitePlatform.KioskIosGarcon:
      case BitePlatform.KioskSignageOsGarcon:
        return true;
      case BitePlatform.Flash:
        return false;
    }
  }

  useOnPremKds(): boolean {
    const fulfillmentI9n = this.getFulfillmentIntegration();
    return !!fulfillmentI9n?.useOnPremKds;
  }

  isAsterHall(): boolean {
    return this.get('orgId') === '5b047999b440310021246853';
  }

  isCFA(): boolean {
    return this.get('orgId') === '5b2d6d2565c2940020f11c7e';
  }
}

namespace GcnLocation {
  // NOTE: This type is duplicated in /db-models/models/location_data.ts
  export type CustomerIdentifierOption = Readonly<
    {
      inputMethod: CustomerIdentifierInputMethod;
    } & (
      | { customerIdentifier: CustomerIdentifier.Address; askAtBeginning?: boolean }
      | { customerIdentifier: CustomerIdentifier.BiteOrderNumber }
      | { customerIdentifier: CustomerIdentifier.GuestEmail; isOptional?: boolean }
      | {
          customerIdentifier: CustomerIdentifier.GuestName;
          askAtBeginning?: boolean;
          enforceLastInitial?: boolean;
        }
      | {
          customerIdentifier: CustomerIdentifier.PhoneNumber;
          askAtBeginning?: boolean;
          isOptional?: boolean;
        }
      | { customerIdentifier: CustomerIdentifier.PosOrderNumber; subStringLength?: number }
      | {
          customerIdentifier: CustomerIdentifier.TableNumber;
          askAtBeginning?: boolean;
          numberOfDigits?: number;
          autoFillFromUrl?: boolean;
        }
      | { customerIdentifier: CustomerIdentifier.VehicleDescription; askAtBeginning?: boolean }
    )
  >;

  // NOTE: This type is partially duplicated in /db-models/models/location_data.ts
  export type Outpost = Readonly<{
    _id: string;
    name: string;
    instructionalImage?: ImageData[];
    pickupInstructions: string;
    roomNumber?: string;
  }>;

  // NOTE: This type is duplicated in /db-models/models/location_data.ts
  export type DiningOption = Readonly<{
    fulfillmentMethod: FulfillmentMethod;
    name?: string;
    customerIdentifierOptions: CustomerIdentifierOption[];
    successScreenFulfillmentTemplate?: string;
    printerReceiptFulfillmentTemplate?: string;
    futureOrdersEnabled?: boolean;
    asapOrdersEnabled?: boolean;
    skipIncludeUtensilsOnFlashOrders?: boolean;
    skipIncludeUtensilsOnKioskOrders?: boolean;
    smsNotificationEnabled?: boolean;
    outposts?: Outpost[];
    deliveryTimeEstimate?: number;
    forceGuestToSelectIfSingleDiningOption?: boolean;
    toGoBagItemId?: string;
    calendarDaysAheadFutureOrders?: number;
  }>;

  export type ReceiptBase64ImageData = {
    receiptHeaderBase64ImageData?: string;
    receiptFooterBase64ImageData?: string;
  };

  // NOTE: This type is duplicated in /db-models/models/location_data.ts
  export type OrderLeadTimeRule = {
    _id: string;
    menuSectionIds: string[];
    maxItemCountFor15Minutes?: number;
    maxItemCountFor30Minutes?: number;
    maxItemCountFor45Minutes?: number;
    maxItemCountFor60Minutes?: number;
    maxItemCountFor90Minutes?: number;
    maxItemCountFor120Minutes?: number;
    maxItemCountFor180Minutes?: number;
    maxItemCountFor240Minutes?: number;
    maxItemCountFor300Minutes?: number;
    maxItemCountFor360Minutes?: number;
  };

  type BasePartialI9n<System extends IntegrationSystem> = Readonly<{
    _id: string;
    system: System;
  }>;

  export type BasePartialLoyaltyI9n<System extends IntegrationSystem.Loyalty> =
    BasePartialI9n<System> &
      Readonly<{
        // loyalty integration
        enableAuthedLoyalty: boolean;
        authMethods: LoyaltyAuthMethod[];
        enableMultipleRewardRedemption: boolean;
        hideStatusAndPoints?: boolean;
      }>;

  export type PartialPunchhLoyaltyI9n<System extends IntegrationSystem.PunchhLoyalty> =
    BasePartialLoyaltyI9n<System> & {
      syncedData?: {
        locationName: string;
        businessName: string;
        shortKey: string;
        printBarcodes: boolean;
        header: string;
        trailer1: string;
        trailer2: string;
        trailer3: string;
        trailer4: string;
        trailer5: string;
      };
      printerBarcodeType?: PunchhPrinterBarcodeType;
    };

  type NonPunchhLoyalty = Exclude<IntegrationSystem.Loyalty, IntegrationSystem.PunchhLoyalty>;
  export type PartialNonPunchhLoyaltyI9n<System extends NonPunchhLoyalty> =
    BasePartialLoyaltyI9n<System>;

  export type PartialLoyaltyI9n =
    | PartialPunchhLoyaltyI9n<IntegrationSystem.PunchhLoyalty>
    | PartialNonPunchhLoyaltyI9n<NonPunchhLoyalty>;

  export type PartialPosI9n<System extends IntegrationSystem.Pos> = BasePartialI9n<System> & {
    syncedData?: {
      specialRequestMaxLength?: number;
    };
  };

  export type PartialSmgI9n = BasePartialI9n<IntegrationSystem.Smg> &
    Readonly<{
      header?: string;
      footer?: string;
    }>;

  export type PartialStoredValueI9n<System extends IntegrationSystem.StoredValue> =
    BasePartialI9n<System> & {
      flashTerminalId?: string;

      useAsSecondaryPayment?: boolean;
      // OloStoredValue/PaytronixGift
      requiresPin?: boolean;
      hasBarcodes?: boolean;
    };

  export type PartialCompCardI9n<System extends IntegrationSystem.CompCard> =
    BasePartialI9n<System> & {
      flashTerminalId?: string;

      // PaytronixCompCard
      requiresPin?: boolean;
      hasBarcodes?: boolean;
    };

  export type PartialFulfillmentI9n = BasePartialI9n<IntegrationSystem.Fulfillment> & {
    useOnPremKds?: boolean;
    kdsIpAddress?: string;
    kdsPort?: string;
    kdsAddresses?: {
      kdsIpAddress: string;
      kdsPort: string;
    }[];
  };

  export type PartialI9n =
    | PartialFulfillmentI9n
    | PartialLoyaltyI9n
    | PartialPosI9n<IntegrationSystem.Pos>
    | PartialSmgI9n
    | PartialStoredValueI9n<IntegrationSystem.StoredValue>;

  export type Image = Readonly<{
    url: string;
    width: number;
    height: number;
  }>;

  // NOTE: This type is duplicated in /db-models/models/location_data.ts
  export type Address = Readonly<{
    line1: string;
    line2?: string;
    crossStreet?: string;
    city: string;
    state: string;
    postalCode: string;
    country?: string;
  }>;

  type ScheduleBoundary = Readonly<{
    /**
     * @description Number of hours: 0-23
     */
    hours: number;
    /**
     * @description Number of minutes: 0-59
     */
    minutes: number;
  }>;

  type DaySchedule = Readonly<{
    from: ScheduleBoundary;
    to: ScheduleBoundary;
    openingHoursString: string;
  }>;

  type OpeningHoursByFulfillmentMethod = Readonly<{
    fulfillmentMethod: FulfillmentMethod;
    mon: DaySchedule;
    tue: DaySchedule;
    wed: DaySchedule;
    thu: DaySchedule;
    fri: DaySchedule;
    sat: DaySchedule;
    sun: DaySchedule;
  }>;

  export type TouchCalibrationConfig = {
    version: 1 | 2;
    minTouchDurationThresholdForClick: number;
    minTouchXYMovementThresholdForClick: number;
    maxHoldToClickTimeMs: number;
    maxFlingDuration?: number;
    scrollResponseBoundary?: number;
    scrollBoundary?: number;
    isAdjustedLocally?: true;
  };

  export type PresetTip = {
    amount: number;
    type: LocationPresetTipType;
  };

  export type Data = GcnModelData &
    Readonly<{
      name: string;
      orgName: string;
      orgId: string;
      orgDomain?: string;
      paymentType: LocationPaymentType;
      state: LocationState;
      integrationById: Record<string, PartialI9n>;
      fullAddress?: Address;
      phoneNumber?: string;
      coordinates?: [number, number];
      urlSlug: string;
      orderChannel: OrderChannel;
      timezone: Timezone;
      activeFulfillmentMethods: FulfillmentMethod[];
      openingHoursByFulfillmentMethod: OpeningHoursByFulfillmentMethod[];
      orderAheadChannelUrls?: {
        orderChannel: OrderChannel.Catering | OrderChannel.Web;
        url: string;
      }[];

      // Properties set by Maitred when returning the location
      diningOptions: DiningOption[];
      paymentI9n?: IntegrationSystem.EcommPayment | IntegrationSystem.KioskPayment;
      paymentI9nId?: string;
      giftCardI9n?: IntegrationSystem.StoredValue;
      giftCardI9nId?: string;
      fulfillmentI9n?: IntegrationSystem.Fulfillment;
      fulfillmentI9nId?: string;
      compCardI9n?: IntegrationSystem.CompCard;
      compCardI9nId?: string;
      loyaltyI9nId?: string;
      orderValidateTimeout: number;
      orderSubmitTimeout: number;
      orderLeadTimeRules?: OrderLeadTimeRule[];
      notifyUserOfOrderStatusUpdates: boolean;
      revalidateAfterFetchingRewards: boolean;
      enablePreRead?: boolean;
      enableEbtCashBenefitAcceptance?: boolean;
      enableEbtFoodStampAcceptance?: boolean;
      touchCalibrationConfig?: TouchCalibrationConfig;

      // Flash Settings
      checkinType?: CheckinType;
      checkinReceiptInstructions?: string;

      // Web Settings
      supportedCardSchemes?: CardSchemeId[];

      // Kiosk Settings
      introImageText?: string;
      introImages?: Image[];
      introImages1080?: Image[];
      useSideNavMenu?: boolean;

      receiptHeaderText?: string;
      receiptFooterText?: string;
      printOrderNumberBarcode?: boolean;
      orderNumberBarcodeFormat?: EpsonBarcodeFormat;
      printOrderedItemPluWithOrderedItems?: boolean;
      printQRCodeWithOrderedItemsPlus?: boolean;
      qrCodeHeader?: string;
      qrCodeItemDelimiter?: string;
      qrCodeItemNeedsBarcodeTypePrefix?: boolean;
      qrCodePrefixFor12DigitPlu?: string;
      qrCodePrefixFor6DigitPlu?: string;
      qrCodePrefixFor39DigitPlu?: string;
      qrCodePrefixForRestOfPlu?: string;
      qrCodeShouldContainFreeMods?: boolean;
      defaultModifierDeselectionPrintOption?: DefaultModifierDeselectPrintOption;

      usesDelphi?: boolean;
      couponsHaveBarcode: boolean;

      // Recommendation Settings
      recommendationsFirstLoadVisibility: RecommendationsFirstLoadVisibility;
      recommendationsInMenuVisibility: RecommendationsFirstLoadVisibility;
      sendRecommendationsRequestToGateway?: boolean;

      // Random Settings
      bankedPointsDemo?: boolean;
      calorieSuffix?: string;
      couponProvider: CouponProvider;
      deliKioskDemo?: boolean;
      optionalPrinting?: boolean;
      showReprintButton?: boolean;
      rewardsDemo?: boolean;
      currencyCode: Currency;
      useOrdersApiV2Flash: boolean;
      useOrdersApiV2Kiosk: boolean;
      usesTallScreenUI?: boolean;
      tipsLevel: LocationTipsLevel;
      presetTips: PresetTip[];

      enableMultipleQuantityItems?: boolean;
      allowPayAtCashier?: boolean;

      hideRequiredSingularModGroup?: boolean;

      // Analytics
      googleAnalyticsApiKey?: string;
      usesApiForTerminalPayments: boolean;

      maxAlcoholicItemCountPerOrder?: number;
      maxSpecialItemCountPerOrder?: number;

      // receipt Images
      receiptHeaderImage?: Image[];
      receiptFooterImage?: Image[];
    }>;
}

export default GcnLocation;
