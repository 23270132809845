import * as Sentry from '@sentry/browser';
import _ from 'underscore';
import { v4 as uuid } from 'uuid';

import { Log } from '@biteinc/common';
import { ModGroupWalletType } from '@biteinc/enums';
import { ArrayHelper } from '@biteinc/helpers';

import GcnHelper from '../gcn_helper';
import { GCNMenuItem } from './gcn_menu_item';
import { GCNMenuSection } from './gcn_menu_section';
import { GCNModel } from './gcn_model';
import { GCNOrderedPriceOption } from './gcn_ordered_price_option';

export const GCNOrderedItem = GCNModel.extend({
  initialize(json, options) {
    const self = this;
    this._uid = uuid();
    // This eventually get passed into getJSON and all the contents are
    // turned into JSON and sent to the server. So we need to make sure this
    // is maintained as the TRUE addon sets and anything else is translated
    // over.
    this._selectionStructByAddonSetId = {};
    // store these default values for future reference
    this._defaultSelectionStructByAddonSetId = {};

    // pos wallet settings across virtual sub groups
    this._walletByParentModGroupId = {};

    if (_.keys(json).length) {
      const orderedPriceOptionJSON = this.get('priceOption');
      let item = gcn.menu.getMenuItemWithId(this.id);

      // It's possible that this item is gone or its price option is gone.
      if (!item || !item.getPriceOptionWithId(orderedPriceOptionJSON._id)) {
        item = new GCNMenuItem(
          _.extend({}, json, {
            priceOptions: [orderedPriceOptionJSON],
            vendorId: this.get('vendor')._id,
          }),
        );
      }

      const priceOption = item.getPriceOptionWithId(orderedPriceOptionJSON._id);

      // We need to know the weight of our item. This lives on the price option sent back from maitred
      // Should I use our price option from the menu and just set the weight?
      // Or do I trust the price option sent back from maitred?
      this.setPriceOption(priceOption, orderedPriceOptionJSON);

      this.item = item;

      if (this.get('section')) {
        this.section = gcn.menu.getMenuSectionWithId(this.get('section')._id);
      }
      // It's possible that the section from which the item was previously
      // ordered is no longer there. In this case, find the section in which
      // it is displayed now.
      if (!this.section) {
        this.section = gcn.menu.getShownMenuSectionThatContainsItemId(this.item.id);
      }
      // It's possible that this section is also gone
      if (!this.section) {
        this.section = new GCNMenuSection(this.get('section'));
      }

      // We need to check if we have any price overrides
      // Specifically for duplicating items that have ingredients that can be swapped out
      this._selectionStructByAddonSetId = self._setSelectionStructByIdFromPriceOptionJson(
        orderedPriceOptionJSON,
        options?.selectionStructByAddonSetId || {},
      );
      if (_.size(this._selectionStructByAddonSetId)) {
        this.trigger('change');
      }
    } else {
      this.item = options.item;
      this.set('_id', this.item.id);
      if (this.item.has('i9nId')) {
        this.set('i9nId', this.item.get('i9nId').toString());
        this.set('posId', this.item.get('posId').toString());
      }
      if (this.item.has('posFields')) {
        this.set('posFields', this.item.get('posFields'));
      }
      this.setOrderedPriceOptionFromPriceOption(options.priceOption);

      if (!this.orderedPO && options.priceOptionId) {
        this.setOrderedPriceOptionFromPriceOption(
          this.item.getPriceOptionWithId(options.priceOptionId),
        );
      }

      if (!this.orderedPO && this.item.priceOptions.length === 1) {
        this.setOrderedPriceOptionFromPriceOption(this.item.priceOptions[0]);
      }

      // Preselect the absolutely necessary default addons
      _.each(this.item.priceOptions, (pOption) => {
        const defaultSelectionStructById =
          GCNOrderedItem.defaultSelectionStructFromPriceOption(pOption);
        _.each(defaultSelectionStructById, (selectionStruct, setId) => {
          self._selectionStructByAddonSetId[setId] = selectionStruct;
          self._defaultSelectionStructByAddonSetId[setId] = selectionStruct;
        });
      });

      if (options.section) {
        this.section = options.section;
      }
      if (!this.section || this.section.isPromoSection()) {
        this.section = gcn.menu.getShownMenuSectionThatContainsItemId(this.item.id);
      }

      if (options.upsellScreen) {
        this.set('upsellScreen', options.upsellScreen);
      }
    }
  },

  _calorieCounterMods(selections) {
    const totalRange = { min: 0, max: 0 };
    _.values(selections).forEach((selectedMod) => {
      const modRange = {
        min: 0,
        max: 0,
        ...selectedMod.model.getCalorieRange(),
      };
      totalRange.min += selectedMod.quantity * modRange.min;
      totalRange.max += selectedMod.quantity * modRange.max;
      _.values(selectedMod.selections).forEach((nestedSelection) => {
        const nestedRange = this._calorieCounterMods(nestedSelection.selections);
        totalRange.min += nestedRange.min;
        totalRange.max += nestedRange.max;
      });
    });
    return totalRange;
  },

  calorieCounter() {
    const totalRange = {
      min: 0,
      max: 0,
      ...this.item.getCalorieRange(),
    };
    _.values(this._getSelectedAddons()).forEach((selectedModWrapper) => {
      const modRange = this._calorieCounterMods(selectedModWrapper.selections);
      totalRange.min += modRange.min;
      totalRange.max += modRange.max;
    });
    return totalRange;
  },

  _getSelectedAddons() {
    return this._selectionStructByAddonSetId;
  },

  createCopy() {
    const selectionStructByAddonSetId = this._selectionStructByAddonSetId;
    const copy = new GCNOrderedItem(this.toJSON(), { selectionStructByAddonSetId });
    if (this.orderedPO) {
      copy.orderedPO = this.orderedPO.createCopy();
    }
    return copy;
  },

  isEditable() {
    // TODO: All fields are editable since all items have special requests.
    // Change this if special requests are enabled per-location.
    // return this.item.priceOptions.length > 1 ||
    //   this.item.priceOptions[0].addonSets.length ||
    //   this.hasStr('specialRequest');
    return true;
  },

  isEqualToOrderedItem(oi) {
    if (this.item.id !== oi.item.id) {
      return false;
    }
    if (!this.orderedPO && !oi.orderedPO) {
      return true;
    }
    if (this.orderedPO.id !== oi.orderedPO.id) {
      return false;
    }
    if (this.get('specialRequest') !== oi.get('specialRequest')) {
      return false;
    }
    const selectionsString1 = this.getSelectionsInStringForm();
    const selectionsString2 = oi.getSelectionsInStringForm();
    if (selectionsString1 !== selectionsString2) {
      return false;
    }

    return true;
  },

  isCustomizable() {
    let isCustomizable = false;
    _.each(this.item.priceOptions, (priceOption) => {
      if (priceOption.addonSets?.length > 0) {
        isCustomizable = true;
      }
    });
    return isCustomizable;
  },

  hasSelectedAnyAddons() {
    return _.keys(this._selectionStructByAddonSetId).length > 0;
  },

  setPriceOption(priceOption, json) {
    this.setOrderedPriceOptionFromPriceOption(priceOption, json);
    this.trigger('change', this);
  },

  setBarcode(barcode) {
    this.scannedBarcode = barcode;
  },

  setWeightAndQuantityOnSelectedPriceOption(weightInLb, quantity) {
    this.orderedPO.setWeightAndQuantity(weightInLb, quantity);
    this.trigger('change', this);
  },

  setQuantityOnSelectedPriceOption(quantity) {
    // TODO: Pass in the unit and check that first?
    if (this.orderedPO) {
      this.orderedPO.setQuantity(quantity);
      Log.info('updated', this.orderedPO, 'with new total', this.getTotal());
      this.trigger('change', this);
    } else {
      Sentry.captureException(new Error('Ordered Price Option was not initialized'), {
        extra: {
          item: this.item,
        },
      });
    }
  },

  setOrderedPriceOptionFromPriceOption(priceOption, json) {
    if (priceOption) {
      this.orderedPO = new GCNOrderedPriceOption(json || {}, { priceOption });
    } else {
      this.orderedPO = null;
    }
  },

  setUpsellScreen(upsellScreen) {
    this.set('upsellScreen', upsellScreen);
  },

  setIntegrationId(integrationId, vendor) {
    if (integrationId) {
      this.set('integrationId', integrationId);
    }
    this.setVendor(vendor);
  },

  setVendor(vendor) {
    if (vendor) {
      this.set('vendor', {
        _id: vendor.id,
        name: vendor.displayName(),
      });
    }
  },

  removeAllAddonsForSet(addonSet) {
    if (this._selectionStructByAddonSetId[addonSet.id]) {
      if (this._defaultSelectionStructByAddonSetId[addonSet.id]) {
        this._selectionStructByAddonSetId[addonSet.id] =
          this._defaultSelectionStructByAddonSetId[addonSet.id];
      } else {
        delete this._selectionStructByAddonSetId[addonSet.id];
      }
      this.trigger('change', this);
    }
  },

  clearSelectedAddonsForOtherPriceOptions() {
    const addonSetIds = this.orderedPO.po.get('addonSetIds');
    _.each(this._selectionStructByAddonSetId, (v, addonSetId) => {
      if (!_.contains(addonSetIds, addonSetId)) {
        delete this._selectionStructByAddonSetId[addonSetId];
      }
    });
  },

  getSelectionStructForAddonSetId(addonSetId) {
    return this._selectionStructByAddonSetId[addonSetId];
  },

  // Returns all the selected addons for the current price options, in the
  // order that would result from an in-order traversal.
  getSelectedAddonRefsForPriceOption() {
    const self = this;
    let addonRefs = [];
    if (this.orderedPO) {
      _.each(this.orderedPO.po.addonSets, (addonSet) => {
        const setSelectionStruct = self._selectionStructByAddonSetId[addonSet.id];
        if (setSelectionStruct) {
          const selected = self._getAddonsFromSelections(setSelectionStruct);
          addonRefs = addonRefs.concat(selected);
        }
      });
    }
    return addonRefs;
  },

  // Returns all the selected addons for the current price options, in the
  // order that would result from an in-order traversal.
  getSelectedAndDeselectedModRefsForPriceOption() {
    const modRefs = [];
    if (this.orderedPO) {
      _.each(this.orderedPO.po.addonSets, (modGroup) => {
        const setSelectionStruct = this._selectionStructByAddonSetId[modGroup.id];
        if (setSelectionStruct) {
          modRefs.push(
            ...this._getAddonsFromSelections(setSelectionStruct, true).map((modRef) => {
              return { isSelected: false, modRef };
            }),
          );
          modRefs.push(
            ...this._getAddonsFromSelections(setSelectionStruct).map((modRef) => {
              return { isSelected: true, modRef };
            }),
          );
        }
      });
    }
    return modRefs;
  },

  _getAddonsFromSelections(setSelectionStruct, useDeselections) {
    const modGroup = setSelectionStruct.model;
    const enableMultipleQuantityItems = !!gcn.menu.settings.get('enableMultipleQuantityItems');
    const addonRefs = [];
    const selections = useDeselections
      ? setSelectionStruct.deselections
      : setSelectionStruct.selections;
    _.each(selections, (addonStruct) => {
      const quantity = addonStruct.quantity ? addonStruct.quantity : 1;
      const selectedByDefaultQuantity = addonStruct.selectedByDefaultQuantity ?? 0;
      // best effort
      // if priceOverrides is populated, we default to updating the prices based on the quantity
      // else we populate the addonRefs based on `enableMultipleQuantityItems` flag
      const isPriceOverridesPopulated = addonStruct.priceOverrides?.length > 0;
      const addon = addonStruct.model;
      const subMods = modGroup.subModsInSet(addon.id);
      const displayName = modGroup.addonNameInSet(addon, addonStruct.selectedPriceOption);
      if (isPriceOverridesPopulated) {
        ArrayHelper.sortAscending(
          addonStruct.priceOverrides,
          (priceOverride) => priceOverride.addonQuantityCap,
        );
        for (let i = 0; i < quantity; i++) {
          // Check if our quantity is within the bounds of the price quantity cap
          // If we do not find one, use the highest bound price
          const priceOverride =
            // eslint-disable-next-line no-loop-func
            addonStruct.priceOverrides.find(({ addonQuantityCap }) => {
              return addonQuantityCap > i;
            }) || _.last(addonStruct.priceOverrides);
          const price = priceOverride.price;
          addonRefs.push({
            addon,
            displayName,
            price,
            quantity: 1,
            selectedByDefaultQuantity,
            ...(subMods && { autoSelectedSubMods: subMods }),
          });
        }
      } else {
        const price = modGroup.addonPriceInSet(addon, addonStruct.selectedPriceOption);
        if (enableMultipleQuantityItems) {
          addonRefs.push({
            addon,
            displayName,
            quantity,
            price,
            selectedByDefaultQuantity,
            ...(subMods && { autoSelectedSubMods: subMods }),
          });
        } else {
          for (let i = 0; i < quantity; i++) {
            addonRefs.push({
              addon,
              displayName,
              price,
              quantity: 1,
              selectedByDefaultQuantity,
              ...(subMods && { autoSelectedSubMods: subMods }),
            });
          }
        }
      }
      _.each(addonStruct.selections, (addonSetStruct) => {
        const selected = this._getAddonsFromSelections(addonSetStruct);
        // Multiply the sub addons by the number of parent addons.
        for (let j = 0; j < quantity; j++) {
          addonRefs.push(...selected);
        }
      });
    });
    return addonRefs;
  },

  getTrackingName() {
    let name = this.item.displayName();
    if (this.orderedPO) {
      const priceOptionName = this.orderedPO.displayName() || '';
      if (priceOptionName.length) {
        name += `:${priceOptionName}`;
      } else {
        name += `:${this.orderedPO.id}`;
      }
    }
    return name;
  },

  // TODO: Still need to call this from the full screen customize view.
  getAddonSets() {
    return this.orderedPO.po.addonSets;
  },

  /**
   *
   * @param {selectionStructure} selectionStruct
   * @param {*} addonSetId ID of current addon set to add to selection structure
   * @param {Array} genealogyAddonStepIds ID array of nested mod groups, and mods, that trail
   *        prior to the current addon set
   */
  extendSelectionStructForSetId(selectionStruct, addonSetId, genealogyAddonStepIds) {
    const parentModGroupId = gcn.menu.getAddonSetWithId(addonSetId).get('parentModGroupId');
    const posWalletSettings = gcn.menu.getAddonSetWithId(addonSetId).get('posWalletSettings');

    if (
      parentModGroupId &&
      posWalletSettings &&
      !this._walletByParentModGroupId[parentModGroupId]
    ) {
      this._walletByParentModGroupId[parentModGroupId] = {
        remainingWalletAmount: posWalletSettings.amount,
        runningQuantity: 1,
      };
    }

    if (_.size(selectionStruct.selections) || _.size(selectionStruct.deselections)) {
      if (genealogyAddonStepIds[0] === addonSetId) {
        this._selectionStructByAddonSetId[addonSetId] =
          this.walletSettingsFromSelections(selectionStruct);
      } else {
        let traversalSelection = this._selectionStructByAddonSetId;
        for (let i = 0; i < genealogyAddonStepIds.length; i++) {
          const key = genealogyAddonStepIds[i];
          traversalSelection = traversalSelection[key].selections;
        }
        traversalSelection[addonSetId] = this.walletSettingsFromSelections(selectionStruct);
      }
    } else if (genealogyAddonStepIds[0] === addonSetId) {
      delete this._selectionStructByAddonSetId[addonSetId];
    } else {
      let traversalSelection = this._selectionStructByAddonSetId;
      for (let i = 0; i < genealogyAddonStepIds.length; i++) {
        const key = genealogyAddonStepIds[i];
        traversalSelection = traversalSelection[key].selections;
      }
      delete traversalSelection[addonSetId];
    }
    this.trigger('change', this);
  },

  getAddonPrice(type, chargePrice, currValue, values, valueCurrIndex) {
    // Dollar wallet
    let runningAmount = currValue;
    let currIndex = valueCurrIndex;
    switch (type) {
      case ModGroupWalletType.Dollar: {
        if (!runningAmount) {
          return { chargePrice, runningAmount };
        }
        if (runningAmount - chargePrice >= 0) {
          runningAmount -= chargePrice;
          return {
            chargePrice: 0,
            runningAmount,
          };
        }
        const remainder = chargePrice - runningAmount;
        runningAmount = 0;
        return {
          chargePrice: remainder,
          runningAmount,
        };
      }
      case ModGroupWalletType.Quantity: {
        // charge max price if we've exceeded the final cap quantity
        if (
          runningAmount > values[values.length - 1].inclusiveQuantityCap ||
          currIndex === values.length
        ) {
          runningAmount += 1;
          return {
            // sometimes the price is still 0 (in the case of a simple X $0-priced items),
            // we should charge the initial price
            chargePrice: values[values.length - 1].price || chargePrice,
            currIndex,
            runningAmount,
          };
          // charge current bracket price
        }
        if (runningAmount <= values[currIndex].inclusiveQuantityCap) {
          const price = values[currIndex].price;

          // move on brackets if we've reached the current inclusive cap
          if (runningAmount === values[valueCurrIndex].inclusiveQuantityCap) {
            currIndex++;
          }

          runningAmount += 1;
          return {
            chargePrice: price,
            currIndex,
            runningAmount,
          };
        }
      }
    }

    return { chargePrice, runningAmount, currIndex };
  },

  walletSettingsFromSelections(setSelectionStruct) {
    // we should also recurse through the children of this mod group
    // and apply the wallet settings to their selections
    Object.keys(setSelectionStruct.selections).forEach((modGroupId) => {
      setSelectionStruct.selections[modGroupId] = this.walletSettingsFromSelections(
        setSelectionStruct.selections[modGroupId],
      );
    });
    const modGroup = setSelectionStruct.model;
    const posWalletSettings = modGroup.get('posWalletSettings');
    const parentModGroupId = modGroup.get('parentModGroupId');

    if (!posWalletSettings) {
      return setSelectionStruct;
    }

    const { values, type, amount = 0 } = posWalletSettings;

    // for dollar value wallets
    let remainingWalletAmount = amount;
    // for quantity value wallets
    let runningQuantity = 1;
    let valueCurrIndex = 0;

    let runningAmountOfParentWallet;

    if (parentModGroupId) {
      remainingWalletAmount =
        this._walletByParentModGroupId[parentModGroupId].remainingWalletAmount || 0;
      runningQuantity = this._walletByParentModGroupId[parentModGroupId].runningQuantity || 0;
      valueCurrIndex = this._walletByParentModGroupId[parentModGroupId].valueCurrIndex || 0;
    }
    Object.keys(setSelectionStruct.selections).forEach((addonId) => {
      const addonSelectionStruct = setSelectionStruct.selections[addonId];

      // iterate over each item in quantity of selected addons
      for (let i = 0; i < addonSelectionStruct.quantity; i++) {
        const { chargePrice, runningAmount, currIndex } = this.getAddonPrice(
          type,
          modGroup.addonPriceInSet(
            addonSelectionStruct.model,
            addonSelectionStruct.selectedPriceOption,
          ),
          type === ModGroupWalletType.Dollar ? remainingWalletAmount : runningQuantity,
          values,
          valueCurrIndex,
        );

        remainingWalletAmount = runningAmount;
        runningQuantity = runningAmount;
        runningAmountOfParentWallet = runningAmount;
        valueCurrIndex = currIndex;

        const overrideLength = addonSelectionStruct.priceOverrides?.length || 0;

        if (!overrideLength) {
          addonSelectionStruct.priceOverrides = [
            {
              addonQuantityCap: i + 1,
              price: chargePrice,
            },
          ];
        } else if (addonSelectionStruct.priceOverrides[overrideLength - 1]?.price !== chargePrice) {
          addonSelectionStruct.priceOverrides.push({
            addonQuantityCap: i + 1,
            price: chargePrice,
          });
        }
      }
    });

    if (parentModGroupId && runningAmountOfParentWallet !== undefined) {
      this._walletByParentModGroupId[parentModGroupId] = {
        remainingWalletAmount: runningAmountOfParentWallet || 0,
        runningQuantity: runningAmountOfParentWallet || 0,
        valueCurrIndex,
      };
    }
    return setSelectionStruct;
  },

  setSelectionStructForSetId(selectionStruct, addonSetId) {
    // If this is a virtual sub group, we need to iterate over all of the selections
    // of the parent mod groups children to ensure we have the correct price overrides.
    // Go through these selections and apply the wallet settings
    const parentModGroupId = gcn.menu.getAddonSetWithId(addonSetId).get('parentModGroupId');
    const posWalletSettings = gcn.menu.getAddonSetWithId(addonSetId).get('posWalletSettings');

    if (parentModGroupId && posWalletSettings) {
      this._walletByParentModGroupId[parentModGroupId] = {
        remainingWalletAmount: posWalletSettings.amount,
        runningQuantity: 1,
      };
      Object.keys(this._selectionStructByAddonSetId).forEach((key) => {
        if (
          key !== addonSetId &&
          gcn.menu.getAddonSetWithId(key).get('parentModGroupId') === parentModGroupId
        ) {
          this._selectionStructByAddonSetId[key] = this.walletSettingsFromSelections(
            this._selectionStructByAddonSetId[key],
          );
        }
      });
    }

    if (_.size(selectionStruct.selections) || _.size(selectionStruct.deselections)) {
      this._selectionStructByAddonSetId[addonSetId] =
        this.walletSettingsFromSelections(selectionStruct);
    } else {
      delete this._selectionStructByAddonSetId[addonSetId];
    }
    this.trigger('change', this);
  },

  getRecalculatedTotal(quantityInSelectionView, weightInSelectionView) {
    if (!this.orderedPO) {
      return 0;
    }
    let total = 0;
    total +=
      this.orderedPO.get('unitPrice') * quantityInSelectionView * (weightInSelectionView || 1);
    _.each(this.getSelectedAddonRefsForPriceOption(), (addonRef) => {
      const addonRefQuantity = quantityInSelectionView * addonRef.quantity;
      total += addonRefQuantity * addonRef.price;
    });
    return total;
  },

  getTotal() {
    return this._calculateItemPrice(this.orderedPO?.get('quantity'), this.orderedPO?.get('weight'));
  },

  // THIS IS USED TO GET THE UNIT PRICE
  getSubtotal() {
    return this._calculateItemPrice(1, 1);
  },

  _calculateItemPrice(quantity, weight) {
    let total = 0;
    if (!quantity) {
      quantity = 1;
    }
    if (!weight) {
      weight = 1;
    }
    if (this.orderedPO) {
      total += quantity * weight * this.orderedPO.get('unitPrice');

      // since price = quantity * unitPrice; we need to take into
      // consideration the quantity * addon.price
      _.each(this.getSelectedAddonRefsForPriceOption(), (addonRef) => {
        const addonRefQuantity = quantity * weight * addonRef.quantity;
        total += addonRefQuantity * weight * addonRef.price;
      });
    }

    return Math.round(total);
  },

  toJSON(uiOnly = false) {
    const json = _.extend(GcnHelper.deepClone(this.attributes), {
      _id: this.item.id,
      name: this.item.displayName(),
      section: {
        _id: this.section.id,
        name: this.section.displayName(),
      },
      priceOption: this.orderedPO.toJSON(this._selectionStructByAddonSetId),
    });
    if (this.item.has('posName')) {
      json.posName = this.item.get('posName');
    }
    if (this.item.get('isRetail')) {
      json.isRetail = true;
    }
    if (this.scannedBarcode) {
      json.scannedBarcode = this.scannedBarcode;
    }
    if (this.section.has('posId')) {
      json.section.posId = this.section.get('posId');
    }
    if (this.section.has('posName')) {
      json.section.posName = this.section.get('posName');
    }
    if (uiOnly && this.item.hasArr('images')) {
      const images = this.item.get('images');
      json.image = images[images.length - 1];
    }

    if (uiOnly) {
      json.total = this.getTotal();
      // we generate a unique id for each item so that we can differentiate items in cart
      json._uid = this._uid;
    }

    return json;
  },

  _setSelectionStructByIdFromPriceOptionJson(priceOptionJSON, originalSelectionStructByAddonSetId) {
    const setSelectionStructById = {};
    _.each(priceOptionJSON.addonSets, (orderedAddonSet) => {
      const addonSet = gcn.menu.getAddonSetWithId(orderedAddonSet._id);
      if (!addonSet) {
        return;
      }

      const selections = this._selectionsFromOrderedAddons(
        orderedAddonSet.items,
        addonSet,
        originalSelectionStructByAddonSetId[orderedAddonSet._id]?.selections || {},
      );
      const deselections = this._selectionsFromOrderedAddons(
        orderedAddonSet.deselectedItems,
        addonSet,
        {}, // I don't think we need to remember price overrides for deselected items
      );

      if (_.size(selections) || _.size(deselections)) {
        const setSelectionStruct = {
          model: addonSet,
          selections,
          quantity: 1,
        };
        if (_.size(deselections)) {
          setSelectionStruct.deselections = deselections;
        }
        setSelectionStructById[orderedAddonSet._id] = setSelectionStruct;
      }
    });
    return setSelectionStructById;
  },

  _selectionsFromOrderedAddons(orderedAddons, addonSet, originalSelections) {
    const enableMultipleQuantityItems = !!gcn.menu.settings.get('enableMultipleQuantityItems');
    const selections = {};
    _.each(orderedAddons, (orderedAddon) => {
      const addon = gcn.menu.getMenuItemWithId(orderedAddon._id);
      // Ignore this mod if it was entirely removed from the menu, or it's
      // still part of the menu but no longer part of this mod group, or if
      // it was added by maitred and needs to be ignored.
      if (
        !addon ||
        !addonSet.containsItemWithId(addon.id) ||
        (orderedAddon.posFields || {}).mustBeIgnored
      ) {
        return;
      }

      if (selections[addon.id]) {
        selections[addon.id].quantity++;
        return;
      }

      const originalSelection = originalSelections[addon.id];

      const addonSelections = {
        model: addon,
        selections: this._setSelectionStructByIdFromPriceOptionJson(
          orderedAddon.priceOption,
          originalSelection?.selections || {},
        ),
        quantity: enableMultipleQuantityItems ? orderedAddon.priceOption.quantity : 1,
        selectedByDefaultQuantity: orderedAddon.selectedByDefaultQuantity,
      };
      if (originalSelection && originalSelection.priceOverrides) {
        addonSelections.priceOverrides = originalSelection.priceOverrides;
      }
      if (originalSelection?.selectedPriceOption) {
        const newSelectedPriceOption = addon.priceOptions.find((po) => {
          return po.id === originalSelection.selectedPriceOption.id;
        });
        addonSelections.selectedPriceOption = newSelectedPriceOption;
      }
      selections[addon.id] = addonSelections;
    });
    return selections;
  },

  getSelectionsInStringForm() {
    return this._stringFromSelectionStructById(this._selectionStructByAddonSetId);
  },

  _stringFromSelectionStructById(selectionStructById) {
    const addonSetIds = _.sortBy(_.keys(selectionStructById));
    const addonSetStrings = [];
    _.each(addonSetIds, (addonSetId) => {
      const selectionStruct = selectionStructById[addonSetId];
      const addonIds = _.sortBy(_.keys(selectionStruct.selections));
      const addonStrings = _.map(addonIds, (addonId) => {
        const addonStruct = selectionStruct.selections[addonId];
        return _.compact([
          `${addonId} (${selectionStruct.model.addonNameInSet(
            addonStruct.model,
            selectionStruct.selectedPriceOption,
          )}):`,
          this._stringFromSelectionStructById(addonStruct.selections),
        ]).join(' ');
      });
      if (addonStrings.length) {
        addonSetStrings.push(
          _.compact([
            `${addonSetId} (${selectionStruct.model.displayName()}): {`,
            addonStrings.join(', '),
            '}',
          ]).join(' '),
        );
      }
    });
    return _.flatten(['{', addonSetStrings.join(', '), '}']).join(' ');
  },
});

// For a given price option, returns a selection struct representing all the
// default selected addons (including nested addons).
GCNOrderedItem.defaultSelectionStructFromPriceOption =
  function defaultSelectionStructFromPriceOption(priceOption, onlyComboBuilders) {
    const self = this;
    const selectionStruct = {};
    _.each(priceOption.addonSets, (addonSet) => {
      if (onlyComboBuilders && !addonSet.get('useAsComboBuilder')) {
        return;
      }
      if (!onlyComboBuilders && addonSet.get('useAsComboBuilder')) {
        return;
      }

      const defaultAddons = _.filter(addonSet.items, (addon) => {
        // A mod with multiple price options cannot be default.
        if (addon.is86d() || addon.priceOptions.length !== 1) {
          return false;
        }
        return addonSet.addonIsSelectedByDefault(addon.id);
      });
      if (defaultAddons.length > 0) {
        const addonSelections = {};
        _.each(defaultAddons, (defaultAddon) => {
          const defaultPriceOption = defaultAddon.priceOptions[0];
          const addonSelectionStruct =
            self.defaultSelectionStructFromPriceOption(defaultPriceOption);
          addonSelections[defaultAddon.id] = {
            model: defaultAddon,
            selections: addonSelectionStruct,
            quantity: addonSet.getAddonSelectedByDefaultCount(defaultAddon.id) || 1,
          };
        });
        selectionStruct[addonSet.id] = {
          model: addonSet,
          selections: addonSelections,
          quantity: 1,
        };
      }
    });
    return selectionStruct;
  };

GCNOrderedItem.getPriceChanges = function getPriceChanges(parentItemJSON) {
  let priceChanges = [];
  if (_.isNumber(parentItemJSON.priceOption.originalPrice)) {
    priceChanges.push({
      name: parentItemJSON.name,
      originalPrice: parentItemJSON.priceOption.originalPrice,
      unitPrice: parentItemJSON.priceOption.unitPrice,
    });
  }
  _.each(parentItemJSON.priceOption.addonSets, (addonSet) => {
    _.each(addonSet.items, (item) => {
      priceChanges = priceChanges.concat(GCNOrderedItem.getPriceChanges(item));
    });
  });
  return priceChanges;
};
