import { Strings } from '@biteinc/common';
import { IntegrationSystem } from '@biteinc/enums';

import { localizeStr } from '~/app/js/localization/localization';

export function terminalBasedIntegrationUsesApiPayments(
  storedValueSystem: IntegrationSystem.StoredValue,
): boolean {
  switch (storedValueSystem) {
    case IntegrationSystem.EigenStoredValue:
    case IntegrationSystem.PinataPayStoredValue:
    case IntegrationSystem.WorldpayStoredValue:
      return true;
    case IntegrationSystem.Archer:
    case IntegrationSystem.Atrium:
    case IntegrationSystem.FreedomPayKioskStoredValue:
    case IntegrationSystem.Givex:
    case IntegrationSystem.OloStoredValue:
    case IntegrationSystem.PaytronixGift:
    case IntegrationSystem.PinataStoredValue:
    case IntegrationSystem.Tcc:
    case IntegrationSystem.Valutec:
      return false;
  }
}

export function getCssClassForCardReader(
  storedValueSystem: IntegrationSystem.StoredValue,
): 'ingenico' | 'msr' | 'hid' | 'verifone' | null {
  switch (storedValueSystem) {
    case IntegrationSystem.Archer:
    case IntegrationSystem.FreedomPayKioskStoredValue:
    case IntegrationSystem.PinataPayStoredValue:
    case IntegrationSystem.WorldpayStoredValue:
      return 'ingenico';

    case IntegrationSystem.EigenStoredValue:
      return 'verifone';

    case IntegrationSystem.Atrium: {
      const hasHid = gcn.kiosk?.get('hasHid');
      const hasMsr = gcn.kiosk?.get('hasMsr');
      switch (true) {
        case hasHid && hasMsr:
          return 'msr';
        case hasHid:
          return 'hid';
        case hasMsr:
          return 'msr';
        default:
          return null;
      }
    }

    case IntegrationSystem.Givex:
    case IntegrationSystem.OloStoredValue:
    case IntegrationSystem.PaytronixGift:
    case IntegrationSystem.PinataStoredValue:
    case IntegrationSystem.Tcc:
    case IntegrationSystem.Valutec:
      if (gcn.kiosk?.get('hasMsr')) {
        return 'msr';
      }
      return null;
  }
}

export function getSecondaryCssClassForCardReader(
  storedValueSystem: IntegrationSystem.StoredValue,
): 'hid' | null {
  switch (storedValueSystem) {
    case IntegrationSystem.Archer:
    case IntegrationSystem.EigenStoredValue:
    case IntegrationSystem.FreedomPayKioskStoredValue:
    case IntegrationSystem.Givex:
    case IntegrationSystem.OloStoredValue:
    case IntegrationSystem.PaytronixGift:
    case IntegrationSystem.PinataPayStoredValue:
    case IntegrationSystem.PinataStoredValue:
    case IntegrationSystem.Tcc:
    case IntegrationSystem.Valutec:
    case IntegrationSystem.WorldpayStoredValue:
      return null;

    case IntegrationSystem.Atrium: {
      const hasHid = gcn.kiosk?.get('hasHid');
      const hasMsr = gcn.kiosk?.get('hasMsr');
      switch (true) {
        case hasHid && hasMsr:
          return 'hid';
        default:
          return null;
      }
    }
  }
}

export function getNativeStoredValueInstructionText(
  storedValueSystem: IntegrationSystem.StoredValue,
): string {
  switch (storedValueSystem) {
    case IntegrationSystem.Archer:
    case IntegrationSystem.EigenStoredValue:
    case IntegrationSystem.FreedomPayKioskStoredValue:
    case IntegrationSystem.PinataPayStoredValue:
    case IntegrationSystem.WorldpayStoredValue:
      return localizeStr(Strings.GIFT_CARD_SUBTITLE);

    case IntegrationSystem.Atrium: {
      const hasHid = gcn.kiosk?.get('hasHid');
      const hasMsr = gcn.kiosk?.get('hasMsr');
      switch (true) {
        case hasHid && hasMsr:
          return localizeStr(Strings.STUDENT_CARD_SUBTITLE_HID_MSR);
        case hasHid:
          return localizeStr(Strings.STUDENT_CARD_SUBTITLE_HID);
        case hasMsr:
          return localizeStr(Strings.STUDENT_CARD_SUBTITLE_MSR);
      }
      return '';
    }

    case IntegrationSystem.Givex:
    case IntegrationSystem.OloStoredValue:
    case IntegrationSystem.PaytronixGift:
    case IntegrationSystem.PinataStoredValue:
    case IntegrationSystem.Tcc:
    case IntegrationSystem.Valutec:
      if (gcn.kiosk?.get('hasMsr')) {
        return localizeStr(Strings.SWIPE_YOUR_GIFT_CARD);
      }
      return '';
  }
}
